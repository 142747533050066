import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/maistho/prog/gustavbylund.se/src/components/layout.tsx";
import { MyWordCloud } from '../components/WordCloud.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Hi!`}</h2>
    <p>{`I’m a developer from Linköping, Sweden. I `}<em parentName="p">{`love`}</em>{` web development and have been building rich web and mobile applications for around 7 years now. It’s amazing how much there is to learn and do in this space.`}</p>
    <p>{`Recently I’ve been building a `}<a parentName="p" {...{
        "href": "https://github.com/ifiske/ifiske"
      }}>{`mobile app for selling fishing permits`}</a>{`, an `}<a parentName="p" {...{
        "href": "https://agdq2019.now.sh"
      }}>{`app for getting notifications during GDQ`}</a>{`, and an `}<a parentName="p" {...{
        "href": "https://inbox-228903.firebaseapp.com/"
      }}>{`Inbox by GMail replacement`}</a>{`.`}</p>
    <h2>{`Some techs I use:`}</h2>
    <MyWordCloud mdxType="MyWordCloud" />
    <h2>{`My past`}</h2>
    <p>{`I studied the `}<a parentName="p" {...{
        "href": "https://liu.se/en/education/program/6kipr"
      }}>{`Bachelor’s Programme in Programming`}</a>{` for three years at Linköping University. I’ve since been running `}<a parentName="p" {...{
        "href": "https://kada.se"
      }}>{`Kåda Development`}</a>{` with two friends. We focus on building mobile experiences for our clients, which mostly pans out to web and/or app development.`}</p>
    <p>{`Creating and maintaining great applications is though work, so I try to make sure that our testing and deployment workflow is as quick as possible. When not shipping new features for our clients I’ve been working on our Continous Integration and Deployment system at Kåda, making sure that we can keep our focus on delivering great experiences.`}</p>
    <h2>{`My Future`}</h2>
    <p>{`I want to `}<em parentName="p">{`keep building`}</em>{` great and interesting things, `}<em parentName="p">{`keep learning`}</em>{` more and `}<em parentName="p">{`keep challenging`}</em>{` myself! In order to do that I want to take on new interesting projects.`}</p>
    <p>{`I’d love working more with mobile web or app development, UX engineering or DevOps.`}</p>
    <p>{`Do you think our futures might align? Send me a message at `}<a parentName="p" {...{
        "href": "mailto:hello@gustavbylund.se"
      }}>{`hello@gustavbylund.se`}</a>{` and we’ll see!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      