export function debounce(fn: Function, delay: number): typeof fn {
  var timeout: any
  var context = this
  var args = arguments
  return function() {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn.apply(context, arguments)
    }, delay)
  }
}
