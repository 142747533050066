import * as React from 'react'
import { debounce } from '../utils/util'

import { wordcloud } from './wordcloud.module.scss'
import { words } from '../wordcloudWords'

require('./seedrandom.min')

const WordCloudLib = require('./wordcloud')

export interface WordCloudProps {
  words: (string | number)[][]
}

export class WordCloud extends React.Component<WordCloudProps> {
  ref = React.createRef<HTMLDivElement>()
  oldSize: ClientRect | DOMRect

  componentDidMount() {
    setTimeout(() => {
      this.drawImmediateWordCloud(20)

      this.oldSize = this.ref.current.getBoundingClientRect()
      window.addEventListener('resize', this.resize)
    },0)
  }
  render() {
    return <div role="img" className={wordcloud} ref={this.ref} />
  }
  resize = () => {
    const node = this.ref.current
    if (!node) {
      window.removeEventListener('resize', this.resize)
      return
    }
    var currSize = node.getBoundingClientRect()
    if (
      currSize.height !== this.oldSize.height ||
      currSize.width !== this.oldSize.width
    ) {
      this.oldSize = currSize
      this.drawWordCloud(2)
    }
  }

  private drawImmediateWordCloud = (speed: number) => {
    ;(Math as any).seedrandom('Gustavs')
    const node = this.ref.current
    WordCloudLib(node, {
      list: this.props.words,
      gridSize: 13,
      drawOutOfBound: false,
      wait: speed,
      rotateRatio: 0.4,
      backgroundColor: null,
      fontFamily: 'Patua One',
      weightFactor: Math.min(Math.max(node.clientWidth / 250, 1), 2),
    })
  }

  private drawWordCloud = debounce(this.drawImmediateWordCloud, 100)
}

export const MyWordCloud: React.FC = () => {
  return <WordCloud words={words} />
}
