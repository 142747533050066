export const words = `26 Full Stack Developer
20 JavaScript
18 HTML
18 git
18 CSS
16 TypeScript
16 Angular
15 NodeJS
15 Linux
15 DevOps
14 Flutter
14 Dart
14 SASS
14 cordova
14 React
14 Ionic
14 CI
12 Android
12 Windows
12 SQL
12 Regular Expressions
12 Networking
12 iOS
12 Security
11 Docker
10 Ruby
10 WebPack
10 Unit Testing
10 Swift
10 Python
10 PHP
8 AngularJS
0`
  .split(/\r?\n/)
  .map(l => {
    var item = l.split(' ')
    return [item.slice(1).join(' '), parseInt(item[0])]
  })
